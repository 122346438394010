.colored-box-container {
  background-color: rgba(40, 42, 58, 0.7);
  border-radius: 20px;
  padding: 20px;
  border: 1px solid rgba(136, 131, 224, 0.3);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
}/* Tab navigation */
.tab-navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  background-color: rgba(50, 50, 70, 0.3);
  border-radius: 20px;
  padding: 5px;
}/* Global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto Mono', monospace;
}

body {
  background-color: #0d0d0d;
  color: white;
  min-height: 100vh;
}

.app {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

.container {
  max-width: 100%;
  padding: 20px;
  z-index: 1;
  position: relative;
}

/* Terminal styles */
.terminal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: calc(100vh - 100px);
}

.terminal {
  background-color: #151723;
  border-radius: 30px;
  width: 100%;
  max-width: 420px;
  padding: 40px 20px;
  position: relative;
  box-shadow: 0 0 20px rgba(136, 131, 224, 0.2);
  overflow: hidden;
}

.terminal:before, .terminal:after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  top: 25px;
}

.terminal:before {
  left: 20px;
}

.terminal:after {
  right: 20px;
}

/* Logo and header */
.logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo-icon {
  font-size: 28px;
  background: linear-gradient(45deg, #a599ff, #d9d4ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo-text {
  font-size: 24px;
  font-weight: 600;
  color: white;
}

/* Header styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

/* Terminal header */
.terminal-header {
  background-color: rgba(70, 70, 100, 0.4);
  border-radius: 25px;
  padding: 8px 16px;
  margin-bottom: 30px;
  text-align: center;
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

/* Wallet connect button */
.wallet-connect-button-container {
  margin-top: 30px;
}

.connect-wallet-button {
  display: block;
  width: 100%;
  padding: 15px;
  border-radius: 50px;
  background-color: #c9c4ff;
  color: #333;
  border: none;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.connect-wallet-button:hover {
  background-color: #d9d4ff;
}

/* Social icons */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 40px 0;
}

.social-icon {
  width: 40px;
  height: 40px;
  background-color: rgba(70, 70, 100, 0.4);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Typography */
h1 {
  font-size: 36px;
  text-align: center;
  margin-bottom: 8px;
}

h1.code-style {
  font-family: 'Roboto Mono', monospace;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #c9c4ff;
}

h3 {
  font-size: 18px;
  margin-bottom: 15px;
  color: #c9c4ff;
}

.subheading {
  text-align: center;
  color: #a8a8c0;
  margin-bottom: 40px;
  padding: 0 20px;
  line-height: 1.6;
}

/* Wallet address */
.wallet-address {
  background-color: rgba(70, 70, 100, 0.4);
  border-radius: 25px;
  padding: 8px 16px;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.copy-icon {
  cursor: pointer;
  opacity: 0.7;
}

.copy-icon:hover {
  opacity: 1;
}

/* Tab navigation */
.tab-navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  background-color: rgba(50, 50, 70, 0.3);
  border-radius: 20px;
  padding: 5px;
}

.tab-button {
  padding: 10px 15px;
  border: none;
  background: transparent;
  color: #a8a8c0;
  cursor: pointer;
  border-radius: 15px;
  transition: all 0.3s ease;
  font-size: 14px;
}

.tab-button.active {
  background-color: rgba(136, 131, 224, 0.3);
  color: white;
}

/* Tab content */
.tab-content {
  padding: 10px 0;
}

/* Morse logo container */
.morse-logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.morse-logo {
  width: 80px;
  height: 80px;
}

/* Eligibility section */
.eligibility-section {
  background-color: rgba(40, 42, 58, 0.7);
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid rgba(136, 131, 224, 0.3);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.eligibility-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.eligibility-badge {
  background-color: #4caf50;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 600;
}

/* Countdown Timer */
.countdown-timer {
  margin-bottom: 20px;
}

.countdown-timer p {
  text-align: center;
  margin-bottom: 10px;
  color: #c9c4ff;
}

.countdown-boxes {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
}

.countdown-box {
  background-color: rgba(70, 70, 100, 0.4);
  border-radius: 10px;
  padding: 8px 5px;
  text-align: center;
}

.countdown-value {
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.countdown-label {
  font-size: 12px;
  color: #a8a8c0;
}

/* Claim info */
.claim-info {
  display: flex;
  justify-content: space-between;
  background-color: rgba(70, 70, 100, 0.4);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
}

.token-value {
  font-size: 24px;
  font-weight: 600;
  color: #c9c4ff;
}

.token-price {
  font-size: 18px;
  font-weight: 600;
  color: #c9c4ff;
}

.eligibility-note {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.5;
}

.success-note {
  display: block;
  color: #4caf50;
  margin-top: 10px;
  font-weight: 600;
}

/* Action buttons */
.action-button {
  display: block;
  width: 100%;
  padding: 15px;
  border-radius: 50px;
  background-color: transparent;
  color: white;
  border: 1px solid #45455a;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  margin-top: 15px;
  transition: all 0.3s ease;
  text-align: center;
  text-decoration: none;
}

.action-button:hover {
  background-color: rgba(70, 70, 100, 0.4);
}

.action-button.primary {
  background-color: #c9c4ff;
  color: #333;
  border: none;
}

.action-button.primary:hover {
  background-color: #d9d4ff;
}

.action-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Messages */
.error-message {
  color: #f44336;
  font-size: 14px;
  margin-top: 10px;
}

.success-message {
  color: #4caf50;
  font-size: 14px;
  margin-top: 10px;
}

/* Referrer info */
.referrer-info {
  background-color: rgba(70, 70, 100, 0.3);
  border-radius: 10px;
  padding: 12px;
  margin: 15px 0;
  font-size: 14px;
}

.referrer-address {
  font-weight: 600;
  margin-left: 5px;
  color: #c9c4ff;
}

/* Buy section */
.buy-description {
  margin-bottom: 20px;
  font-size: 14px;
}

.swap-interface {
  margin-bottom: 20px;
}

.swap-input, .swap-output {
  background-color: rgba(70, 70, 100, 0.3);
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 5px;
}

.swap-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
}

.balance-info {
  color: #a8a8c0;
  font-size: 12px;
}

.swap-amount {
  display: flex;
  align-items: center;
}

.swap-amount input {
  flex: 1;
  background-color: transparent;
  border: none;
  font-size: 18px;
  color: white;
  padding: 5px 0;
  outline: none;
}

.token-badge {
  background-color: rgba(136, 131, 224, 0.3);
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
}

.token-badge.morse {
  background-color: rgba(136, 131, 224, 0.5);
}

.swap-arrow {
  display: flex;
  justify-content: center;
  margin: -2px 0;
  position: relative;
  z-index: 1;
}

.swap-arrow svg {
  background-color: #151723;
  border-radius: 50%;
  padding: 5px;
  color: #c9c4ff;
}

/* Referral section */
.referral-description {
  margin-bottom: 20px;
  font-size: 14px;
}

.referral-link-section {
  margin-bottom: 20px;
}

.referral-link-section label {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
}

.referral-link-input, .contract-address-input {
  display: flex;
  gap: 10px;
}

.referral-link-input input, .contract-address-input input {
  flex: 1;
  background-color: rgba(70, 70, 100, 0.3);
  border: none;
  border-radius: 10px;
  padding: 12px 15px;
  color: white;
  font-size: 14px;
  outline: none;
}

.copy-button {
  background-color: rgba(136, 131, 224, 0.3);
  color: white;
  border: none;
  border-radius: 10px;
  padding: 0 15px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
}

.copy-button:hover {
  background-color: rgba(136, 131, 224, 0.5);
}

/* Earnings section */
.earnings-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 20px;
}

.earnings-box {
  background-color: rgba(70, 70, 100, 0.3);
  border-radius: 10px;
  padding: 15px;
}

.earnings-value {
  font-size: 18px;
  font-weight: 600;
  color: #c9c4ff;
  margin-top: 5px;
}

/* Referrals list */
.referrals-list {
  margin-bottom: 20px;
}

.referrals-container {
  background-color: rgba(70, 70, 100, 0.3);
  border-radius: 10px;
  padding: 15px;
}

.referrals-container ul {
  list-style-type: none;
}

.referrals-container li {
  font-size: 14px;
  margin-bottom: 8px;
  color: #a8a8c0;
}

/* Contract details */
.contract-details {
  border-top: 1px solid rgba(136, 131, 224, 0.3);
  padding-top: 20px;
  margin-top: 20px;
}

.contract-address {
  margin-bottom: 15px;
}

.contract-address label {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
}

.contract-links {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.contract-links a {
  color: #c9c4ff;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
}

.contract-links a:hover {
  text-decoration: underline;
}

/* Background */
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: #0d0d0d;
}

.grid-line {
  stroke: rgba(100, 100, 150, 0.1);
  stroke-width: 0.5;
}

/* Dog icon at bottom right */
.dog-icon {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  z-index: 10;
}

/* Terminal content */
.terminal-content {
  padding: 20px 0;
}

.command-line {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.prompt {
  color: white;
  margin-right: 10px;
}

.command {
  color: white;
}

.result {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.result-label {
  color: white;
}

.result-value {
  color: white;
}

.dots {
  margin: 20px 0;
  font-size: 24px;
  line-height: 0.8;
  color: white;
}

/* Morse code */
.morse-code {
  text-align: center;
  margin-bottom: 20px;
  font-size: 16px;
  color: #aaa;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .terminal {
    max-width: 100%;
  }
  
  .claim-info {
    flex-direction: column;
    gap: 15px;
  }
}