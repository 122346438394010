@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --pi-purple: #6022c0;
  --pi-purple-light: #8845e5;
  --pi-purple-dark: #4a15a3;
  --pi-white: #ffffff;
  --pi-gray: #f5f5f7;
  --pi-dark: #1e1e1e;
}

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--pi-dark);
  color: white;
  overflow-x: hidden;
}

/* Background futuristik */
.pi-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #1e1e1e 0%, #2d1b47 50%, #1e1e1e 100%);
  z-index: -2;
}

.pi-bg::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(circle at 20% 30%, rgba(134, 70, 252, 0.15) 0%, transparent 20%),
    radial-gradient(circle at 80% 70%, rgba(134, 70, 252, 0.1) 0%, transparent 20%);
  z-index: -1;
}

.pi-grid {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(to right, rgba(134, 70, 252, 0.05) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(134, 70, 252, 0.05) 1px, transparent 1px);
  background-size: 50px 50px;
  z-index: -1;
}

.pi-circles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.pi-circle {
  position: absolute;
  border-radius: 50%;
  background: rgba(134, 70, 252, 0.05);
  border: 1px solid rgba(134, 70, 252, 0.1);
  animation: float 20s infinite linear;
}

.pi-circle:nth-child(1) {
  width: 300px;
  height: 300px;
  top: 20%;
  left: -150px;
}

.pi-circle:nth-child(2) {
  width: 400px;
  height: 400px;
  top: 50%;
  right: -200px;
  animation-delay: -5s;
}

.pi-circle:nth-child(3) {
  width: 200px;
  height: 200px;
  bottom: -100px;
  left: 30%;
  animation-delay: -10s;
}

@keyframes float {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-30px) rotate(180deg);
  }
  100% {
    transform: translateY(0) rotate(360deg);
  }
}

/* Container */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
}

/* Card futuristik */
.card {
  background: rgba(25, 25, 35, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 1.5rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(134, 70, 252, 0.2);
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(134, 70, 252, 0.1) 0%, transparent 100%);
  z-index: -1;
}

.card::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle at center, rgba(134, 70, 252, 0.1) 0%, transparent 30%);
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: -1;
}

.card:hover::after {
  opacity: 1;
}

/* Buttons */
.btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border: none;
  z-index: 1;
}

.btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, transparent 25%, rgba(255, 255, 255, 0.2) 50%, transparent 75%);
  background-size: 200% 200%;
  z-index: -1;
  animation: shimmer 2s infinite linear;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.btn:hover::before {
  opacity: 1;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.btn-primary {
  background: linear-gradient(to right, var(--pi-purple) 0%, var(--pi-purple-light) 100%);
  color: white;
}

.btn-primary:hover {
  background: linear-gradient(to right, var(--pi-purple-light) 0%, var(--pi-purple) 100%);
  transform: translateY(-2px);
}

.btn-secondary {
  background: rgba(134, 70, 252, 0.15);
  color: white;
  border: 1px solid rgba(134, 70, 252, 0.3);
}

.btn-secondary:hover {
  background: rgba(134, 70, 252, 0.25);
  transform: translateY(-2px);
}

/* Forms */
.form-control {
  display: block;
  width: 100%;
  padding: 0.75rem;
  border-radius: 8px;
  background-color: rgba(30, 30, 40, 0.7);
  color: white;
  border: 1px solid rgba(134, 70, 252, 0.3);
  outline: none;
  transition: all 0.3s ease;
}

.form-control:focus {
  box-shadow: 0 0 0 2px rgba(134, 70, 252, 0.5);
  border-color: var(--pi-purple-light);
}

/* Progress bar */
.gradient-bg {
  background: linear-gradient(to right, var(--pi-purple) 0%, var(--pi-purple-light) 100%);
  animation: pulse 1.5s infinite alternate;
}

@keyframes pulse {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
  }
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  letter-spacing: -0.02em;
}

/* Logo Animation */
.pi-logo {
  position: relative;
  display: inline-block;
  font-size: 2rem;
  font-weight: 800;
  color: var(--pi-white);
  text-shadow: 0 0 15px rgba(134, 70, 252, 0.7);
}

.pi-logo::before {
  content: "π";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: var(--pi-purple-light);
  animation: glitch 3s infinite alternate;
  opacity: 0.7;
}

@keyframes glitch {
  0%, 100% {
    transform: translate(0);
  }
  20% {
    transform: translate(-2px, 2px);
  }
  40% {
    transform: translate(-2px, -2px);
  }
  60% {
    transform: translate(2px, 2px);
  }
  80% {
    transform: translate(2px, -2px);
  }
}

/* Glow Effects */
.pi-glow {
  position: relative;
}

.pi-glow::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 20px 2px rgba(134, 70, 252, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: inherit;
  z-index: -1;
}

.pi-glow:hover::after {
  opacity: 1;
}
/* Animasi logo Pi */
.pi-animated-logo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
}

.pi-symbol {
  font-weight: 700;
  color: #fff;
  z-index: 3;
  text-shadow: 0 0 10px rgba(134, 70, 252, 0.8);
  position: relative;
  animation: pulse 2s infinite alternate;
}

.pi-glow-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(134, 70, 252, 0.4) 0%, rgba(134, 70, 252, 0) 70%);
  z-index: 1;
  animation: pulse 2s infinite alternate;
}

.pi-outer-circle {
  position: absolute;
  top: -15%;
  left: -15%;
  width: 130%;
  height: 130%;
  border-radius: 50%;
  border: 1px solid rgba(134, 70, 252, 0.3);
  z-index: 0;
  animation: rotate 10s linear infinite;
}

.pi-outer-circle::before {
  content: "";
  position: absolute;
  top: 0;
  left: 45%;
  width: 10%;
  height: 10%;
  background-color: rgba(134, 70, 252, 0.8);
  border-radius: 50%;
}

/* Efek neon pada cards */
.card {
  position: relative;
}

.card::after {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  background: linear-gradient(60deg, 
    #6e45e2, #88e3ff, #e447b4, #6e45e2
  );
  border-radius: 18px;
  z-index: -2;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.card:hover::after {
  opacity: 0.5;
  animation: border-glow 3s linear infinite;
}

@keyframes border-glow {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 300% 0%;
  }
}

/* Button hover efek */
.btn-primary {
  position: relative;
  overflow: hidden;
}

.btn-primary::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.3) 0%, transparent 70%);
  opacity: 0;
  transform: scale(0.5);
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.btn-primary:hover::after {
  opacity: 1;
  transform: scale(1);
}

/* Particle canvas background */
.pi-canvas-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  pointer-events: none;
}

/* Floating elements animation */
.float-element {
  animation: floating 5s infinite ease-in-out;
}

.float-element-slow {
  animation: floating 8s infinite ease-in-out;
}

.float-element-fast {
  animation: floating 3s infinite ease-in-out;
}

@keyframes floating {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* Shimmer effect for progress bar */
.progress-shimmer {
  position: relative;
  overflow: hidden;
}

.progress-shimmer::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(50%);
  }
}

/* Typing animation for welcome text */
.typing-animation {
  border-right: 2px solid rgba(134, 70, 252, 0.8);
  white-space: nowrap;
  overflow: hidden;
  width: 0;
  animation: typing 3.5s steps(40, end) forwards, blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: rgba(134, 70, 252, 0.8) }
}

/* Add these to the existing animations in index.css */
@keyframes pulse {
  from {
    opacity: 0.7;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1.05);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes float {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-30px) rotate(180deg);
  }
  100% {
    transform: translateY(0) rotate(360deg);
  }
}
.swap-card {
  background: rgba(25, 25, 35, 0.7);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(134, 70, 252, 0.2);
  transition: all 0.3s ease;
}

.swap-card:hover {
  box-shadow: 0 0 25px rgba(134, 70, 252, 0.2);
}

/* Dropdown styling */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content {
  min-width: 160px;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid rgba(134, 70, 252, 0.2);
}

/* Animasi shimmer untuk button swap */
@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.animate-shimmer {
  animation: shimmer 2s infinite;
}

/* Animasi dots untuk processing */
.swap-animation {
  display: flex;
  align-items: center;
}

.swap-animation-dot {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: white;
  border-radius: 50%;
  animation: dotPulse 1.5s infinite ease-in-out;
}

.swap-animation-dot.dot1 {
  animation-delay: 0s;
}

.swap-animation-dot.dot2 {
  animation-delay: 0.2s;
}

.swap-animation-dot.dot3 {
  animation-delay: 0.4s;
}

@keyframes dotPulse {
  0%, 100% {
    transform: scale(0.8);
    opacity: 0.6;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
}

/* Glow effect untuk input fields */
.form-control:focus {
  box-shadow: 0 0 0 2px rgba(134, 70, 252, 0.5);
  transition: all 0.3s ease;
}

/* Token icon dalam dropdown */
.token-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: linear-gradient(45deg, #6022c0, #8845e5);
  margin-right: 8px;
}

/* Futuristic scanner effect */
.scanner-active {
  position: relative;
  overflow: hidden;
}

.scanner-active::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, 
    rgba(134, 70, 252, 0) 0%, 
    rgba(134, 70, 252, 0.8) 50%, 
    rgba(134, 70, 252, 0) 100%
  );
  animation: scan 2s infinite;
}

@keyframes scan {
  0% {
    top: 0;
  }
  100% {
    top: 100%;
  }
}

/* Futuristic AI analysis section */
.ai-analysis {
  border-left: 2px solid rgba(134, 70, 252, 0.5);
  padding-left: 10px;
}

.ai-analysis-header {
  display: flex;
  align-items: center;
  color: rgba(134, 70, 252, 0.8);
  font-weight: 600;
  margin-bottom: 5px;
}

.ai-analysis-icon {
  background: linear-gradient(45deg, #6022c0, #8845e5);
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  font-size: 12px;
}

/* Fancy swap arrow button */
.swap-arrow-btn {
  position: relative;
  z-index: 10;
  border: 3px solid #1e1e2f;
  background: linear-gradient(45deg, #6022c0, #8845e5);
  color: white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.swap-arrow-btn:hover {
  transform: rotate(180deg);
  box-shadow: 0 6px 15px rgba(134, 70, 252, 0.5);
}

/* Token amount input */
.token-amount-input {
  background: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  width: 100%;
  outline: none;
}

/* Token selector button */
.token-selector {
  display: flex;
  align-items: center;
  background: rgba(134, 70, 252, 0.1);
  border: 1px solid rgba(134, 70, 252, 0.2);
  border-radius: 16px;
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.token-selector:hover {
  background: rgba(134, 70, 252, 0.2);
}

.token-selector-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(134, 70, 252, 0.2);
}

/* Holographic effect for swap button on hover */
.holographic-btn {
  position: relative;
  overflow: hidden;
  background: linear-gradient(45deg, #6022c0, #8845e5);
  z-index: 1;
}

.holographic-btn::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transform: rotate(45deg);
  animation: holographic 3s linear infinite;
  z-index: -1;
}

@keyframes holographic {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}

/* Pi Network AI glow */
.pi-ai-indicator {
  display: inline-flex;
  align-items: center;
  color: rgba(134, 70, 252, 0.8);
  font-size: 0.75rem;
}

.pi-ai-indicator::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #8845e5;
  margin-right: 5px;
  animation: pulse 2s infinite;
}

/* Route optimization animation */
.route-line {
  stroke-dasharray: 15;
  stroke-dashoffset: 15;
  animation: dash 2s linear infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Roboto Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0d0d0d;
  color: white;
}

/* Header styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

/* Morse code styles */
.morse-code {
  text-align: center;
  margin-bottom: 20px;
  font-size: 16px;
  color: #aaa;
}

/* Dots for loading indicator */
.dots {
  margin: 20px 0;
  font-size: 24px;
  line-height: 0.8;
  color: white;
}

/* Custom styles for rainbow kit */
[data-rk] {
  --rk-radii-modal: 16px;
  --rk-colors-accentColor: #9D94FF;
  --rk-colors-accentColorForeground: white;
  --rk-fonts-body: 'Roboto Mono', monospace;
}