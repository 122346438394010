/* Swap Interface Styles */

.swap-container {
    background-color: rgba(29, 30, 43, 0.6);
    border-radius: 20px;
    padding: 20px;
    position: relative;
    max-width: 420px;
    margin: 0 auto;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
  }
  
  /* Header */
  .swap-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .swap-header h2 {
    color: #fff;
    margin: 0;
    font-size: 22px;
  }
  
  .swap-settings {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background-color: rgba(136, 131, 224, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #a8a8c0;
    transition: all 0.3s ease;
  }
  
  .swap-settings:hover {
    background-color: rgba(136, 131, 224, 0.2);
    color: #c9c4ff;
  }
  
  /* Settings Panel */
  .settings-panel {
    background-color: rgba(40, 42, 58, 0.8);
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid rgba(136, 131, 224, 0.2);
  }
  
  .settings-panel h3 {
    color: #fff;
    font-size: 16px;
    margin: 0 0 15px 0;
  }
  
  .slippage-setting {
    margin-bottom: 10px;
  }
  
  .slippage-setting label {
    display: block;
    color: #a8a8c0;
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .slippage-options {
    display: flex;
    gap: 8px;
  }
  
  .slippage-options button {
    background-color: rgba(136, 131, 224, 0.1);
    border: none;
    border-radius: 8px;
    padding: 6px 12px;
    color: #a8a8c0;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.2s ease;
  }
  
  .slippage-options button.active {
    background-color: rgba(136, 131, 224, 0.3);
    color: #fff;
  }
  
  .custom-slippage {
    display: flex;
    align-items: center;
    background-color: rgba(136, 131, 224, 0.1);
    border-radius: 8px;
    padding: 0 8px;
  }
  
  .custom-slippage input {
    width: 40px;
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 14px;
    padding: 6px 0;
    text-align: right;
    outline: none;
  }
  
  .custom-slippage span {
    color: #a8a8c0;
    font-size: 14px;
  }
  
  /* Token Input Container */
  .token-input-container {
    background-color: rgba(40, 42, 58, 0.5);
    border-radius: 16px;
    padding: 12px;
    margin-bottom: 12px;
    border: 1px solid rgba(70, 70, 100, 0.3);
  }
  
  .token-input-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  
  .token-input-header span {
    color: #a8a8c0;
    font-size: 13px;
  }
  
  .token-balance {
    font-size: 12px;
  }
  
  .token-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .token-input input {
    flex: 1;
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    padding: 0;
    margin-right: 8px;
    outline: none;
    width: 100%;
    min-width: 0;
  }
  
  .token-input input::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  
  .token-selector {
    position: relative;
  }
  
  .token-selected {
    display: flex;
    align-items: center;
    background-color: rgba(136, 131, 224, 0.2);
    border-radius: 12px;
    padding: 8px;
    width: auto;
  }
  
  .token-selected.morse-token {
    background-color: rgba(201, 196, 255, 0.2);
  }
  
  .token-selected.bnb-token {
    background-color: rgba(243, 186, 47, 0.2);
  }
  
  .token-icon-wrapper {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    flex-shrink: 0;
  }
  
  .token-logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .token-symbol {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    white-space: nowrap;
  }
  
  .token-max {
    background-color: rgba(136, 131, 224, 0.3);
    color: #c9c4ff;
    font-size: 12px;
    font-weight: 600;
    padding: 2px 6px;
    border-radius: 4px;
    margin-left: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .token-max:hover {
    background-color: rgba(136, 131, 224, 0.5);
  }
  
  /* Swap Arrow */
  .swap-arrow {
    width: 36px;
    height: 36px;
    background-color: rgba(136, 131, 224, 0.2);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #c9c4ff;
    margin: -8px auto;
    position: relative;
    z-index: 1;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .swap-arrow:hover {
    background-color: rgba(136, 131, 224, 0.3);
    transform: scale(1.05);
  }
  
  /* Fee Information */
  .fee-info {
    background-color: rgba(40, 42, 58, 0.3);
    border-radius: 12px;
    padding: 12px;
    margin: 15px 0;
  }
  
  .fee-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 14px;
  }
  
  .fee-row:last-child {
    margin-bottom: 0;
  }
  
  .fee-row span:first-child {
    color: #a8a8c0;
  }
  
  .fee-row span:last-child {
    color: #fff;
  }
  
  .fee-low {
    color: #4caf50 !important;
  }
  
  .ai-router {
    display: flex;
    align-items: center;
    color: #c9c4ff !important;
    position: relative;
  }
  
  .ai-router::after {
    content: "⚡";
    margin-left: 4px;
  }
  
  .router-tooltip {
    position: absolute;
    top: -30px;
    right: 0;
    background-color: rgba(40, 42, 58, 0.9);
    padding: 6px 10px;
    border-radius: 8px;
    font-size: 12px;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease;
    border: 1px solid rgba(136, 131, 224, 0.3);
    color: #fff;
    pointer-events: none;
  }
  
  .ai-router:hover .router-tooltip {
    visibility: visible;
    opacity: 1;
  }
  
  /* Error Message */
  .error-message {
    color: #ff6b6b;
    font-size: 14px;
    margin: 10px 0;
    text-align: center;
  }
  
  /* Swap Button */
  .swap-button {
    background: linear-gradient(90deg, #9D94FF 0%, #C9C4FF 100%);
    border: none;
    border-radius: 12px;
    color: #1a1b23;
    font-size: 16px;
    font-weight: 600;
    padding: 14px;
    width: 100%;
    cursor: pointer;
    margin-top: 15px;
    transition: all 0.3s ease;
  }
  
  .swap-button:hover {
    transform: translateY(-1px);
    box-shadow: 0 5px 15px rgba(136, 131, 224, 0.3);
  }
  
  .swap-button.disabled {
    background: rgba(136, 131, 224, 0.2);
    color: #a8a8c0;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
  
  /* AI Badge */
  .ai-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    margin-top: 15px;
    color: #a8a8c0;
    font-size: 12px;
  }
  
  /* AI Analysis Status */
  .ai-analysis-status {
    display: flex;
    align-items: center;
    background-color: rgba(136, 131, 224, 0.1);
    border-radius: 8px;
    padding: 8px 12px;
    margin-bottom: 15px;
    border: 1px solid rgba(136, 131, 224, 0.2);
  }
  
  .ai-analysis-loader {
    width: 16px;
    height: 16px;
    border: 2px solid rgba(136, 131, 224, 0.3);
    border-top: 2px solid #c9c4ff;
    border-radius: 50%;
    margin-right: 10px;
    animation: spin 1s linear infinite;
  }
  
  .ai-analysis-text {
    color: #c9c4ff;
    font-size: 14px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Responsive adjustments */
  @media (max-width: 480px) {
    .swap-container {
      padding: 15px;
      margin: 0 10px;
    }
    
    .token-input input {
      font-size: 20px;
    }
    
    .token-selected {
      padding: 6px 10px;
    }
    
    .token-logo {
      width: 20px;
      height: 20px;
    }
  }